.Page-Layout{
    .Content{
        .page-body-header {
            margin-top: 70px;
            margin-bottom: -70px;

            @media (min-width: 992px) {
                margin-left: 150px;
            }
            @media (max-width: 768px) {
                margin-top: 155px;
                margin-bottom: -130px;
            }
        }
       
        .page-body {
            min-height: calc(100vh - 70px);
            background:#E5E5E5;
            overflow: auto;
            margin-top: 70px;
            padding: 10px;

            &.no-padding {
                padding: 0;
            }

            &.white {
                background-color: white;
            }

            @media (min-width: 992px) {
                margin-left: 150px;
                padding: 25px 100px;
            }

            @media (max-width: 768px) {
                margin-top: 130px;

                &.no-padding {
                    padding: 25px 0;
                }
            }
        }
    }
   
}