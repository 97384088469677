.clientCallsWrapper{

    .podcast-card-title{
        text-overflow:ellipsis;
        overflow:hidden;
        display: -webkit-box !important;
       -webkit-line-clamp: 1 !important;
       -webkit-box-orient: vertical;
    }
    .cardDetailWrapper{
        padding-left: 0px;
        padding-right: 10px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;}
        .categoryDetail{
            margin: 0 15px;
            text-overflow:ellipsis;
            overflow:hidden;
            display: -webkit-box !important;
           -webkit-line-clamp: 2 !important;
           -webkit-box-orient: vertical;
            white-space: normal;
    };

    @media screen and (max-width:767px) {
        margin-top: 80px;
    }
    .article-wrapper{
        .cardHeadWrapper{
            .cardBox{
                height: 100% !important;
            }
            .cardHeader{
                  background: black;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-bottom-left-radius: 6px !important;
                  border-top-right-radius: 0px !important;
            };
            img{
                width: 40px;
                height: 40px;
            }
        };

        .cardDetailWrapper{
            padding-left: 0px;
            padding-right: 0px;

            .cardTitle{
                p{
                    font-weight: 600;
                    font-size: 16px;
                    margin: 16px 13px 16px 13px;
                };
            };

            .cardData{
                display: flex; 
                margin-left: 13px;
        
                .categoryText{
        
                        color: #939393;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 15px;
                }
                .categoryType{
                        color: #000000;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 15px;
                        margin-left: 4px;
                        text-transform: capitalize;
                }
                .categoryDetail{
                        color: #000000;
                        font-weight: 400;
                        font-size: 14px;
                        padding-right: 15px;
                        line-height: 19px;
                };
            };

            .dateWrraper{
                align-items:center; 
                margin-bottom:15px;
                gap:20px;
                padding: 0;
                margin-top: 10px;
                @media screen and (max-width:767px){
                    padding: 0 13px;
                    }
                p{
                    margin: 0;
                    padding-left:13px;
                    color: #939393;
                    font-size: 12px;
                    font-weight: 400;
                    @media screen and (max-width:767px){
                        padding: 0;
                        }
                }
                .mediaIcons{
                            width: 35px;
                            height: 35px;
                            cursor: default !important;
                        }
            };
            
        };
    };
}