.setting-wrapper{
    padding: 49px;
}
.setting-title{
    font-size: 20px;
    font-weight: 400;
    color: #4A5981;
    margin-bottom: 27px;
}
.setting-list{
    padding: 26px;
    background-color: #ffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    box-shadow: 1px 1px 4px 2px #c4c4c4a6;
    border-radius: 8px;
    cursor: pointer;
}
.setting-list p{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #000000;

}
.setting-list img{
height: 16px;
width: 10px;
}